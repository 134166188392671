<div class="mat-elevation-z0 log-items-table-container">
  <table mat-table class="full-width-table" matSort aria-label="Elements">
    <!-- Id Column -->
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef >{{'STATUS' | translate}}</th>
      <td mat-cell *matCellDef="let row">{{row.status}}</td>
    </ng-container>

    <ng-container matColumnDef="searchResults">
      <th mat-header-cell *matHeaderCellDef>{{"SEARCH_RESULTS" | translate}}</th>
      <td *matCellDef="let row" mat-cell>
        <div fxLayout="column">
          <span>{{ ("TOTAL" | translate) + ": " + (row.searchResults?.totalCount || 0) }}</span>
          <span>{{ ("TOUCHED" | translate) + ": " + (row.searchResults?.touchedCount || 0) }}</span>
          <span>{{ ("SUCCESS" | translate) + ": " + (row.searchResults?.successCount || 0) }}</span>
          <span>{{ ("SUC/TOUCH" | translate) + ": " + ((row.searchResults?.successCount || 0) / (row.searchResults?.touchedCount || 0) || 0).toFixed(2)  }}</span>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="completed">
      <th mat-header-cell *matHeaderCellDef>{{"COMPLETED" | translate}}</th>
      <td *matCellDef="let row" mat-cell>
        <app-popup-select-complete [selected]="row.completedBy"
                                   (changed)="onSelectedProfilesChange($event, row)">
        </app-popup-select-complete>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef >{{'ACTIONS' | translate}}</th>
      <td mat-cell *matCellDef="let row">
        <button mat-icon-button (click)="deleteRow(row.id)">
          <mat-icon>delete</mat-icon>
        </button>
        <button mat-icon-button (click)="updateRow(row)">
          <mat-icon>save</mat-icon>
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'DATE' | translate}}</th>
      <td mat-cell *matCellDef="let row">
        {{row.date}}
          <input [matDatepicker]="dt" [(ngModel)]="row.date" (ngModelChange)="updateDate($event, row)" class="invisible-input">
          <mat-datepicker-toggle *ngIf="!readonly" [for]="dt"></mat-datepicker-toggle>
          <mat-datepicker #dt></mat-datepicker>
      </td>
    </ng-container>

    <ng-container matColumnDef="duration">
      <th mat-header-cell *matHeaderCellDef >{{'DURATION' | translate}}</th>
      <td mat-cell *matCellDef="let row">
        <div class="text editable-cell" (click)="selectedDuration=row" *ngIf="(selectedDuration!==row); else editableDuration">
          {{row.durationMinutes | duration}}
        </div>

        <ng-template #editableDuration>
          <div>
            <mat-form-field [style.width.px]="100" appearance="outline" class="full-width small-input">
<!--              <mat-label>{{'DURATION' | translate}}</mat-label>-->
              <input matInput type="number" [(ngModel)]="row.durationMinutes"
              (ngModelChange)="updateDuration(row)"
              [ngModelOptions]="{updateOn: 'blur'}"
              fxFlex>
              <span matSuffix>min.</span>
            </mat-form-field>
          </div>
        </ng-template>
      </td>
    </ng-container>

    <ng-container matColumnDef="category">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'CATEGORY' | translate}}</th>
      <td mat-cell *matCellDef="let row">
<!--        {{row.category?.title}}-->
        <div *ngIf="!readonly; else noEditCategory"
          (click)="selectedComment = row"
          (focus)="selectedComment = row"
          fxLayout="row"
          class="edit-border"
          (mouseenter)="showEditCategoryIcon[row.id] = true"
          (mouseleave)="showEditCategoryIcon[row.id] = false">
          <div *ngIf="(selectedComment !== row || selectedComment.hasOwnProperty('adId')); else editCategory"> {{row.category?.title}} </div>

          <span fxFlex fxLayoutAlign="end end" *ngIf="selectedComment != row && showEditCategoryIcon[row.id]">
            <mat-icon>edit</mat-icon>
          </span>

          <ng-template #editCategory>
            <mat-form-field class="example-full-width" appearance="standard">
              <input type="text" matInput [(ngModel)]="row.category" (focusin)="initOptions(row)"
                     [matAutocomplete]="auto" (ngModelChange)="onCategoryChange(row)">
              <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (closed)="focusOut(row)">
                <mat-option *ngFor="let option of filteredOptions | async" [value]="option" >
                  {{option.title}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </ng-template>
        </div>

        <ng-template #noEditCategory>{{row.category?.title}}</ng-template>

      </td>
    </ng-container>



    <!-- Name Column -->
    <ng-container matColumnDef="comment" >
      <th mat-header-cell *matHeaderCellDef >{{'COMMENT' | translate}}</th>
      <td mat-cell *matCellDef="let row">
        <div *ngIf="readonly" style="overflow-wrap: anywhere;" >
            <div [innerHTML]="row.comment | linkify"></div>
        </div>
        <ng-container *ngIf="!readonly">
          <div style="overflow-wrap: anywhere;" (click)="activateCommentEdit(row)"
               *ngIf="(activateComment!==row || activateComment.hasOwnProperty('adId')); else editable"
               [innerHTML]="row.comment | linkify"
          >

          </div>

          <ng-template #editable >
            <div>
              <textarea [(ngModel)]="currentComment"
                        (ngModelChange)="updateComment(row, currentComment)"
                        [ngModelOptions]="{updateOn: 'blur'}"
                        style="border: none" fxFlex [rows]="5">
              </textarea>
            </div>
          </ng-template>
        </ng-container>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns" cdkDrag></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator
      [length]="dataSource?.totalCount"
      [pageIndex]="0"
      [pageSize]="5"
      [pageSizeOptions]="[5, 25, 50, 100, 250]">
  </mat-paginator>
</div>
