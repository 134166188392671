import { Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { GroupByPipe } from 'ngx-pipes';
import { ActionItemStatus, FvPlaningDialogActionItem } from 'src/app/free-vacancies/fv-planing-dialog-action-item/fv-planing-dialog-action-item';
import {
  EventLanguage,
  GetPlannerEventsByCompanyGQL,
  GetPlannerEventsByOrderGQL,
  MarketingEventsFieldsFragment
} from 'src/generated/graphql';
import { CompanyAdsTableDataSource } from './company-ads-table-datasource';

@Component({
  selector: 'app-company-ads',
  templateUrl: './company-ads.component.html',
  styleUrls: ['./company-ads.component.scss']
})
export class CompanyAdsComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<MarketingEventsFieldsFragment>;
  companyId: string;
  @Input() orderId: string;
  @Input() useFilter = true;
  @Input() readOnly = true;
  @Output() save = new EventEmitter<FvPlaningDialogActionItem>();
  @Output() done = new EventEmitter<FvPlaningDialogActionItem>();
  @Output() delete = new EventEmitter<FvPlaningDialogActionItem>();


  dataSource: CompanyAdsTableDataSource;
  displayedColumns = ['date', 'title', 'adId', 'externalPlatformId', 'category',
                      'channels', 'duration', 'price', 'status', 'completed', 'language',
                      'comment',
                     ];

  dateLte: string;
  dateGte: string;
  range: FormGroup;

  editCell = {};
  showEditIcon: any = {};

  statuses = ActionItemStatus;

  @ViewChildren(MatInput,{read:ElementRef}) inputs:QueryList<ElementRef>;

  selectedUsersForNewAction: string[] = [] ;
  eventLanguages = Object.entries(EventLanguage).map(([key, value]) => ({
    label: key,
    value: value as EventLanguage,
  }));


  constructor(
    private getPlannerEventsByCompanyGQL: GetPlannerEventsByCompanyGQL,
    private getPlannerEventsByOrderGQL: GetPlannerEventsByOrderGQL,
    private groupBy: GroupByPipe,
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    if (!this.readOnly) {
      this.displayedColumns.push('action')
    }
    this.companyId = this.activatedRoute.snapshot?.parent?.paramMap?.get('id');
    this.dataSource = new CompanyAdsTableDataSource(this.groupBy, this.getPlannerEventsByCompanyGQL, this.getPlannerEventsByOrderGQL, this.companyId, this.orderId);
    this.range = new FormGroup({
      start: new FormControl(this.useFilter ? moment().format('YYYY-MM-DD') : null),
      end: new FormControl()
    });
    this.range.valueChanges.subscribe(v => {
      if(v?.start && this.useFilter) {
        this.dataSource.filters.next(
          {
            start: v?.start?.format('YYYY-MM-DD'),
            end: v?.end?.format('YYYY-MM-DD')
          }
        )
      }
    })
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.table.dataSource = this.dataSource;
  }

  edit(cellId, element) {
    this.editCell[cellId] = true;
    setTimeout(()=>{
      this.inputs.toArray().find((x)=> {
        return x.nativeElement.getAttribute('name') == element;
      }).nativeElement.focus()
    }, 100)
  }

  stopEditing(row, column) {
    setTimeout(() => {
      this.editCell[`${row.id}_${column}`] = false;
    }, 50)
  }

  onSave(action) {
    this.save.emit({...action, date: moment(action.date).format('YYYY-MM-DD'),
      completedBy: action.completedBy?.map(it => it.id ? it.id : atob(it).split(':')[1])
    });
  }

  onDone(action) {
    this.done.emit({
      ...action,
      date: moment(action.date).format('YYYY-MM-DD'),
      completedBy: action.completedBy?.map(it => it.id ? it.id : atob(it).split(':')[1])
    });
    // this.action.status = this.statuses.done;
  }

  onDelete(row) {
    this.delete.emit(row);
  }

  onOpenNew(action) {
    window.open(
      `https://www.workis.online/client/orders?orderId=${action?.workisJobId}`,
      '_blank'
    );
  }

  dateFormattedBasedOnLocale(date) {
    return new Date(date).toLocaleDateString(this.translate.currentLang);
  }

  onSelectedProfilesChange(userIds, action) {
    const data = {...action, completedBy: userIds?.map(it => btoa('UserProfileMType:' + it))}
    this.onSave(data)
  }

}
