<div class="container">
  <div
    mat-dialog-title
    fxLayout='row'
    class='dialog-header'
    fxLayoutAlign='space-between center'
  >
    <h6>{{ this.task ? this.task.category?.title : 'CREATE_TASK' | translate}}</h6>
    <button mat-icon-button mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class='links'>
    <div *ngIf='task?.order || data?.prefill?.order'>
      <span>{{'ORDER.ORDER' | translate}}: </span>
      <a [routerLink]='getOrderEditUrl()' target='_blank'>{{task?.order?.specification?.positionTitle || orderCtrl?.value.specification?.positionTitle}}</a>
    </div>
    <div *ngIf='task?.candidate'>
      <span>{{'CANDIDATE' | translate}}: </span>
      <a href='{{getCandidateEmployeeLivasUrl(true)}}'
         target='_blank'>{{task.candidate?.firstName}} {{task.candidate?.lastName}}</a>
      <a mat-icon-button href='{{getCandidateEmployeeLivasUrl(true)}}'
         matTooltip='{{"OPEN_EMPLOYEE_CARD_IN_LIVAS" | translate}}'
         target='_blank'
      >
        <mat-icon inline>open_in_new</mat-icon>
      </a>
    </div>
  </div>

  <mat-dialog-content class='reduced-component'>
    <form [formGroup]='newTaskForm'>
      <div class='first-row' fxLayout='row' fxLayoutGap='16px'>
        <!-- date field -->
        <mat-form-field appearance='outline' fxFlex='50'>
          <input matInput [matDatepicker]='picker' placeholder='Choose a date' formControlName='date'>
          <mat-datepicker-toggle matSuffix [for]='picker'></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>

        <!-- status field -->
        <mat-form-field appearance="outline" fxFlex="50">
          <mat-label>{{'STATUS' | translate}}</mat-label>
          <mat-select formControlName="status" (selectionChange)="onNewStatusSelected($event)">
            <mat-option *ngFor="let status of statuses" [value]="status">
              {{status}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="first-row" fxLayout="row" fxLayoutGap="16px">
        <!-- client field -->
        <app-company-selector
          *ngIf="!selectedCategoryConfig || selectedCategoryConfig?.showCompany"
          [fxFlex]="(!selectedCategoryConfig || selectedCategoryConfig?.showOrder) ? 50 : 100"
          [country]='country?.id'
          [selectedCompany]='selectedCompanyWithOldId'
          [disabled]='task?.order'
          [order]='task?.order'
          (selectedCompanyCountry)='onSelectedCompanyCountry($event)'
          (companyChange)="this.orderCtrl.setValue(null); this.orderCtrl.enable(); onCompanyChange($event)"
        ></app-company-selector>

        <!-- order field select -->
        <mat-form-field
          *ngIf="!selectedCategoryConfig || selectedCategoryConfig?.showOrder"
          appearance='outline'
          [fxFlex]="(!selectedCategoryConfig || selectedCategoryConfig?.showCompany) ? 50 : 100"
        >
          <mat-label>{{'ORDER.ORDER' | translate}}</mat-label>
          <input type="text" matInput formControlName="order" [matAutocomplete]="orderAutocomplete">
          <mat-autocomplete #orderAutocomplete="matAutocomplete" [displayWith]="orderDisplayFn">
            <mat-option *ngFor="let order of foundOrders" [value]="order">
              {{ 'ID: ' + decode(order?.id).split(':')[1] + ' - ' + (order?.specification?.positionTitle || '') + '  (' + order?.company?.name + ')' }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>

      <!-- country field select -->
      <mat-form-field appearance='outline'>
        <mat-label>{{ 'COUNTRY' | translate }}</mat-label>
        <mat-select matNativeControl formControlName='country'>
          <mat-option *ngFor='let country of countries' [value]='country.id'>
            {{ ('COUNTRIES.' + country.isoCode.toUpperCase()) | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- comment field text area -->
      <mat-form-field *ngIf='!selectedCategoryConfig || selectedCategoryConfig?.showDescription' appearance='outline'>
        <mat-label>{{ 'DESCRIPTION' | translate }}</mat-label>
        <textarea matInput formControlName='comment'></textarea>
      </mat-form-field>

      <app-category-selector
        style='width: 100%'
        [selected]='categoryInputCtrl?.value'
        [categories]='categories'
        [user]='user'
        (selectedChange)='onCategoryChange($event)'
      ></app-category-selector>

      <mat-form-field appearance='outline' *ngIf='!selectedCategoryConfig || selectedCategoryConfig?.showDuration'>
        <mat-label>{{ 'DURATION' | translate }}</mat-label>
        <input type='number' matInput formControlName='durationMinutes'>
        <span matSuffix>{{ 'MINUTES' | translate }}</span>
      </mat-form-field>

      <!--- custom fields -->
      <ng-container *ngIf='isAddedCustomFields()'>
        <ng-container *ngFor='let customField of selectedCategoryConfig?.customFields.edges; let first=first'>
          <div [ngSwitch]='customField.node.fieldType.fieldType' class='custom-field'>
            <mat-divider *ngIf='first' style='margin-bottom: 16px'></mat-divider>

            <div *ngIf='first' style='color: red; margin-bottom: 8px'>
              {{ 'FILL_CUSTOM_FIELDS_HERE' | translate }}:
            </div>

            <ng-container *ngSwitchCase='fieldTypes.RatingWithComment'>
              <app-rating-with-comment-input
                style='width: 100%'
                label='{{customField.node?.name | translate}}'
                hint='{{customField.node?.hintText | translate}}'
                [valueController]="newTaskForm.get('customFields').get(customField.node?.id)"
              ></app-rating-with-comment-input>
            </ng-container>

            <ng-container *ngSwitchCase='fieldTypes.Boolean'>
              <app-boolean-customfield
                style='margin-bottom: 16px'
                label='{{customField.node?.name | translate}}'
                [valueController]="newTaskForm.get('customFields').get(customField.node?.id)"
              ></app-boolean-customfield>
            </ng-container>

            <ng-container *ngSwitchCase='fieldTypes.Date'>
              <app-date-customfield
                label='{{customField.node?.name | translate}}'
                hint='{{customField.node?.hintText | translate}}'
                [valueController]="newTaskForm.get('customFields').get(customField.node?.id)"
              ></app-date-customfield>
            </ng-container>

            <ng-container *ngSwitchCase='fieldTypes.Heperlink'>
              <app-hyperlink-customfield
                label='{{customField.node?.name}}'
                [valueController]="newTaskForm.get('customFields').get(customField.node?.id)"
              ></app-hyperlink-customfield>
            </ng-container>

            <ng-container *ngSwitchCase='fieldTypes.Numeric'>
              <app-planned-calls-for-today
                label='{{customField.node?.name | translate}}'
                hint='{{customField.node?.hintText | translate}}'
                [valueController]="newTaskForm.get('customFields').get(customField.node?.id)"
              ></app-planned-calls-for-today>
            </ng-container>

            <!-- Add other cases for different field types here -->
          </div>
        </ng-container>
      </ng-container>

      <app-popup-select-complete
        [selected]='task?.completedBy || [user]'
        (changed)='onSelectedProfilesChange($event)'
      ></app-popup-select-complete>

      <mat-checkbox *ngIf="!task" class="notify-assignee" formControlName="sendNotificationToAssignee">
        {{ 'NOTIFY_DESIGNATED_USERS' | translate }}
      </mat-checkbox>
    </form>
  </mat-dialog-content>

  <mat-dialog-actions style='justify-content: space-between; align-content: center'>
    <div style='gap: 16px; align-content: center'>
      <button mat-flat-button *ngIf='task' color='warn' (click)='deleteTask(task.id)'>{{ 'DELETE' | translate }}</button>
      <button mat-stroked-button *ngIf='task' (click)='copyTask(task)'>{{ 'COPY' | translate }}</button>
    </div>

    <button mat-flat-button class='rounded-btn' color='accent' [disabled]='newTaskForm.invalid' (click)='onSubmit()'>
      {{ (task ? 'SAVE' : 'CREATE') | translate }}
    </button>
  </mat-dialog-actions>

  <div *ngIf="newTaskDate" class='new-task-info'>{{ ('NEW_TASK_WILL_BE_CREATED_INFO' | translate) + ' ' + newTaskDate }}</div>
</div>
