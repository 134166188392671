import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { ApolloQueryResult } from '@apollo/client/core';

import {
  CreateOperationsTaskGQL,
  CreateOperationsTaskInput,
  DeleteTaskGQL,
  GetNextTaskDateGQL,
  GetOperationsTaskGQL,
  GetOperationsTasksGQL,
  GetOverdueTasksGQL,
  GetTaskCustomFieldValuesAndCategoriesGQL,
  TaskCustomFieldUpdateValuesGQL,
  UpdateOperationsTaskGQL,
  UpdateOperationsTaskInput,
  UpdateOperationsTaskMutationVariables,
  TaskType,
  GetOperationsTaskQuery
} from '../../generated/graphql';
import { NotificationService } from '../shared/notification.service';
import {
  ScheduledTaskCreateNotificationComponent
} from './planner-table/scheduled-task-create-notification/scheduled-task-create-notification.component';

@Injectable({
  providedIn: 'root'
})
export class TaskServiceService {
  constructor(
    private operationTasksGQL: GetOperationsTasksGQL,
    private updateOperationTaskGQL: UpdateOperationsTaskGQL,
    private createOperationTaskGQL: CreateOperationsTaskGQL,
    private createUpdateCustomFieldsGQL: TaskCustomFieldUpdateValuesGQL,
    private getTaskGQL: GetOperationsTaskGQL,
    private getOverdueTasksGQL: GetOverdueTasksGQL,
    private getCustomFieldValuesAndCategories: GetTaskCustomFieldValuesAndCategoriesGQL,
    private getNextTaskDateGQL: GetNextTaskDateGQL,
    private notificationService: NotificationService,
    private translate: TranslateService,
    private deleteTaskGQL: DeleteTaskGQL
  ) { }

  getTasks(params?: any) {
    if (!params) {
      params = { orderBy: '-date' };
    } else if (!params.orderBy) {
      params.orderBy = '-date';
    }

    return this.operationTasksGQL.watch(params).valueChanges;
  }

  deleteTask(taskId: string, refetchQueries?: string[]) {
    return this.deleteTaskGQL.mutate({
      taskId
    }, { refetchQueries });
  }

  getOverdueTasks(dashboardId: any, overdueOnly?: boolean) {
    const queryInput: { [key: string]: any } = {
      dashboardId,
      orderBy: 'date',
      statuses: 'IN_PROGRESS,PLANNED,ANNOUNCE'
    };

    if (overdueOnly) {
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      queryInput.date_Lte = yesterday.toLocaleDateString('lt-LT');
    }

    return this.getOverdueTasksGQL.fetch(queryInput, { fetchPolicy: 'no-cache' });
  }

  updateTask(task: UpdateOperationsTaskInput, refetchQueries?: string[]) {
    return this.updateOperationTaskGQL.mutate({
      taskId: task.id,
      status: task.status,
      order: task.order,
      company: task.company,
      category: task.category,
      date: task.date,
      comment: task.comment,
      completedBy: task.completedBy,
      durationMinutes: task.durationMinutes,
      country: task.country
    }, { refetchQueries });
  }

  updateTaskPartial(task: UpdateOperationsTaskMutationVariables, refetchQueries?: string[]):
    Observable<{ data: { updateOperationsTask: { task: TaskType } } }>
  {
    return this.updateOperationTaskGQL.mutate(task, { refetchQueries }) as
      Observable<{ data: { updateOperationsTask: { task: TaskType } } }>;
  }

  createTask(task: CreateOperationsTaskInput, refetchQueries?: string[]) {
    return this.createOperationTaskGQL.mutate({
      ...task
      // duration: task.duration
    }, { refetchQueries });
  }

  getTask(taskId: string): Observable<ApolloQueryResult<GetOperationsTaskQuery>> {
    return this.getTaskGQL.fetch({ id: taskId });
  }

  getNextTaskDate(taskId: string) {
    return this.getNextTaskDateGQL.fetch({id: taskId});
  }

  getTaskCustomFieldValuesAndCategories(taskId: string) {
    return this.getCustomFieldValuesAndCategories.fetch({ taskId }, { fetchPolicy: 'no-cache' });
  }

  saveTaskCustomFieldValues(taskId, customFieldValues) {
    customFieldValues = customFieldValues.map(customFieldValue => {
      if (customFieldValue.dateValue) {
        customFieldValue.dateValue = new Date(customFieldValue.dateValue).toISOString().substring(0, 10);
      }
      return customFieldValue;
    });

    return this.createUpdateCustomFieldsGQL.mutate({
      taskId,
      customFieldValues
    });
  }

  callScheduledTaskCreateMessage(data: any) {
    const task = data.data.updateOperationsTask.task;
    const edges = task?.taskSet.edges || [];
    if (edges?.length) {
      this.notifyAboutScheduledTaskCreate(edges);
    } else if ((data.data.updateOperationsTask.task.schedules.edges.length || task.createdBySchedule) && task.status === 'DONE') {
      this.notifyAboutScheduledTaskDontCreate(task.schedules.edges[0]?.node.endDate || task.createdBySchedule?.endDate || 'Unknown');
    }
  }

  notifyAboutScheduledTaskCreate(edges: any) {
    this.notificationService.notify(
      '',
      0,
      10000,
      {component: ScheduledTaskCreateNotificationComponent, data: {
        date: edges[0].node.date,
        link: `${location.origin}/planner/${encodeURIComponent(edges[0].node.id)}`
      }}
    );
  }

  notifyAboutScheduledTaskDontCreate(date: string) {
    this.notificationService.notify(
      this.translate.instant('TASK_WAS_NOT_CREATED_MESSAGE', {date}),
      1,
      5000
    );
  }
}
