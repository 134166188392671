<div class='wrapper' [formGroup]='commentsGroup'>
  <div class='head' fxLayout fxLayoutAlign='space-between center' fxLayoutGap='12px'>
    <div fxLayout='column' fxLayoutGap='4px'>
      <span class='manager'>{{ data.managerName }}</span>
      <span>{{ data.metricGroup }}</span>
    </div>

    <mat-icon (click)='closeDialog()'>close</mat-icon>
  </div>

  <div class='content' formArrayName='comments'>
    <div *ngFor='let reason of data.reasonSet; let i = index' class='reason-card' fxLayout='column'>
      <div class='client-candidate' fxLayout fxLayoutAlign='space-between'>
        <div fxFlex='45'>
          {{ 'CANDIDATE' | translate }}: <span>
          <ng-container *ngFor='let candidate of candidatesDict[reason.id]; let i = index'>
            <div class="candidate-item">
              <a
                *ngIf="candidate.employeeLivasId"
                href="{{ regionService.configureBaseUrlByRegion('lt') }}personalas.php?id={{ candidate.employeeLivasId }}"
                target='_blank'
              >
                <mat-icon svgIcon="engineering"></mat-icon>
              </a>

              <mat-icon *ngIf="!candidate.employeeLivasId" svgIcon="engineering" [style.color]="'gray'"></mat-icon>

              <ng-container *ngIf="candidate.livasId; else candidateNoLink">
                <a
                  href="{{ regionService.configureBaseUrlByRegion('lt') }}interviu.php?id={{ candidate.livasId }}"
                  target='_blank'
                >
                  {{ candidate.firstName }} {{ candidate.lastName }}
                </a>
                <span *ngIf='i < (candidatesDict[reason.id]?.length - 1)'>, </span>
              </ng-container>

              <ng-template #candidateNoLink>
                <span>{{ candidate.firstName }} {{ candidate.lastName }}</span>
                <span *ngIf='i < (candidatesDict[reason.id]?.length - 1)'>, </span>
              </ng-template>
            </div>
          </ng-container>

          <ng-container *ngIf='!candidatesDict[reason.id]'>---</ng-container>
        </span>
        </div>

        <div fxFlex='45'>
          {{ 'CLIENT.CLIENT' | translate }}: <span>
            <ng-container *ngFor='let client of clientsDict[reason.id]; let i = index'>
              <ng-container *ngIf="client.id; else candidateNoLink">
                <a href="{{ regionService.configureBaseUrlByRegion('lt') }}klientai.php?id={{ client.livasId }}"
                   target='_blank'>
                  {{ client.name }}
                </a>
                <span *ngIf='i < (clientsDict[reason.id]?.length - 1)'>, </span>
              </ng-container>

              <ng-template #candidateNoLink>
                <span>{{ client.name }}</span>
                <span *ngIf='i < (clientsDict[reason.id]?.length - 1)'>, </span>
              </ng-template>
            </ng-container>

            <ng-container *ngIf='!clientsDict[reason.id]'>---</ng-container>
          </span>
        </div>
      </div>

      <div class='reason'>
        {{ 'REASON_TITLE' | translate }}: <span>{{ reason.metricClientReason?.title || '---' }}</span>
      </div>

      <mat-form-field appearance="outline" class="new-comment">
        <textarea matInput [formControlName]='i' [placeholder]="'NEW_COMMENT' | translate"></textarea>
      </mat-form-field>

      <button
        mat-flat-button
        color="primary"
        [disabled]='comments.at(i)?.value === reason.comment || isSaving'
        (click)='saveComment(reason, comments.at(i)?.value)'
      >
        {{ "COMMON.SAVE" | translate }}
      </button>
    </div>
  </div>
</div>
