import {AfterViewInit, Component, Input, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { LogItemsTableDataSource } from './log-items-table-datasource';
import {OrderActionsLogService} from '../order-actions-log.service';
import {SaCategoryGroupType, SaCategoryType, SelectionActionListItemFieldsFragment, SelectionActionType} from '../../../../generated/graphql';

import * as moment from 'moment';
import {BehaviorSubject, merge} from 'rxjs';
import { PopupSelectCompleteComponent } from '../actions-log-dialog/popup-select-complete/popup-select-complete.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-log-items-table',
  templateUrl: './log-items-table.component.html',
  styleUrls: ['./log-items-table.component.scss'],
  encapsulation : ViewEncapsulation.None,
})
export class LogItemsTableComponent implements AfterViewInit, OnInit {

  @Input() orderId: string;
  @Input() country: string;
  @Input() companyId: string;
  @Input() showActions = true;
  @Input() readonly = false;
  @Input() listenToValueChanges = true;
  private nameInput: any;
  private users: any;
  @Input() set toggleMarketingEvents(value: boolean) {
    if (typeof value === 'boolean') {
      this.loadOrderActions(this.orderId, value);
    }
  }

  dateLte: string;
  dateGte: string;
  @Input() set dateRange(value: moment.Moment[]) {
    if (value[1]) {
      this.dateLte = value[1].format('YYYY-MM-DD');
    }
    if (value?.length && value[0] && value[1]) {
      this.dateGte = value[0].format('YYYY-MM-DD');
      this.dateLte = value[1].format('YYYY-MM-DD');
      this.loadOrderActions(this.orderId, false);
    }
  }
  @Input() set categories(value: string[]) {
    this.selectedCategories = value;
    this.loadOrderActions(this.orderId, false);
  }
  selectedCategories: string[] = [];
  selectedComment: any;
  showEditCategoryIcon: any = {};
  completed: any = {};

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<SelectionActionListItemFieldsFragment>;
  dataSource: LogItemsTableDataSource;

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = [ 'date', 'category', 'comment', 'duration', 'searchResults', 'completed'];
  activateComment: any;
  activteDuration: any;
  filteredOptionsSubject = new BehaviorSubject([]);
  filteredOptions = this.filteredOptionsSubject.asObservable();
  fullListCategories = [];
  colors = ['#3b88e1', '#3BE13EFF', '#d23a2b', '#4c8d11', '#88640FFF', '#840F88FF'];
  displayFn = (it) =>  it?.title;
  currentComment: string;

  constructor(
    public dialog: MatDialog,
    private orderActionsLogService: OrderActionsLogService
  ) {}

  ngOnInit() {
    this.dataSource = new LogItemsTableDataSource(this.orderActionsLogService);
    this.loadActionCategories(this.country);

    if(this.showActions) {
      this.displayedColumns.push('actions');
    }
  }

  ngAfterViewInit() {
    this.sort.direction = 'desc';
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.table.dataSource = this.dataSource;
    this.loadOrderActions(this.orderId, this.toggleMarketingEvents);
  }

  private loadOrderActions(orderId, toggleMarketingEvents) {

    if (this.dataSource) {
      merge(this.sort.sortChange.asObservable()).subscribe(
        valueChange => {
          this.sort.direction = valueChange.direction;
          this.dataSource.sortByDate(valueChange);
        }
      );
      merge(this.paginator.page.asObservable()).subscribe(
        valueChange => {
          this.dataSource.sortByDate({direction: this.sort.direction})
        }
      );
      this.dataSource.loadRecords(
        orderId,
        toggleMarketingEvents,
        null,
        null,
        null,
        this.companyId,
        this.dateLte,
        this.dateGte,
        this.listenToValueChanges,
        this.selectedCategories
      );
    }


    // this.orderActionsLogService.getSelectionActionsList(orderId).subscribe(
    //   res => {this.dataSource.data = res.map(it => it.node); this.dataSource = this.dataSource;}
    // );

  }

  updateDate($event: any, row) {
    const newRow = {
      ...row,
      date: moment($event).format('YYYY-MM-DD')
    };
    this.updateRow(newRow);
  }

  updateRow(row) {
    this.orderActionsLogService.updateSelectionActionListItem(row).subscribe( res => {this.selectedComment = undefined});
  }

  focusOut(row) {
    if(!row.category) {
      this.updateRow(row);
      this.dataSource.categoryMap[row.id] = row.category;
    } else if (typeof row.category === 'string') {
      row.category = this.dataSource.categoryMap[row.id];
    }
  }

  updateDuration(row) {
    this.updateRow(row);
    if (this.activteDuration === row) {
      this.activteDuration = undefined;
    }
  }

  updateComment(row) {

    this.updateRow({ ...row, comment: this.currentComment });
    if (this.activateComment === row) {
      this.activateComment = undefined;
    }
  }

  deleteRow(actionId) {
    this.orderActionsLogService.deleteSelectionActionListItem(actionId).subscribe();
  }

  private loadActionCategories(country) {
    this.orderActionsLogService.getSACategoriesGroupsList(country).subscribe(
      value => {

        this.fullListCategories = value.data.selectionActionCategoryGroups.edges.map(it => it.node);
      }
    );
  }

  onCategoryChange(row: SelectionActionType) {
    if (!row.category) {
      this.filteredOptionsSubject.next(this._filter(''));
      return;
    }
    const category: string | SaCategoryType = row.category;
    if(typeof category === 'string') {
      this.filteredOptionsSubject.next(this._filter(category));
    } else {
      this.dataSource.categoryMap[row.id] = row.category;
      this.updateRow(row);
    }
  }

  initOptions(row: SelectionActionType) {
    this.filteredOptionsSubject.next(this._filter(row.category?.title? row.category.title : ''));
  }

  private _filter(value: string) {
    const filterValue = value?.toLowerCase() || "";
    return this.fullListCategories.map(cg => {
      if(cg.title.toLowerCase().includes(filterValue)) {
        return cg.sacategorySet.edges.map(it2 => it2.node)
      } else {
        return cg.sacategorySet.edges.map(it2 => it2.node).filter(option => option.title.toLowerCase().includes(filterValue))
      }
    }).reduce((flat, next) => flat.concat(next), []);
  }

  saveRow(id) {

  }

  addCompletedBy(users, row) {
    const usersIds = users.map((user) => {
      return user.id;
    });
    row.completedBy = usersIds;
    this.updateRow(row);
  }


  onSelectedProfilesChange(profiles, row) {
    const updatedRow = Object.assign({}, row);
    updatedRow.completedBy = profiles;
    this.updateRow(updatedRow);
  }
  private parseGlobalToPrivate(it){
    return atob(it).split(':')[1];
  }

  activateCommentEdit(row) {
    this.activateComment = row;
    this.currentComment = row.comment;

  }
}
