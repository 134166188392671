import { DataSource } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { first, map } from 'rxjs/operators';
import {Observable, of as observableOf, merge, BehaviorSubject} from 'rxjs';
import {SelectionActionListItemFieldsFragment} from '../../../../generated/graphql';
import {OrderActionsLogService} from '../order-actions-log.service';

/**
 * Data source for the LogItemsTable view. This class should
 * encapsulate all logic for fetching and manipulating the displayed data
 * (including sorting, pagination, and filtering).
 */
export class LogItemsTableDataSource extends DataSource<SelectionActionListItemFieldsFragment> {

  data: SelectionActionListItemFieldsFragment[] = [];
  paginator: MatPaginator;
  sort: MatSort;
  items: { actions: any[], events: any[] } = {actions: [], events: []};
  paginatedData = [];

  constructor(private dataService: OrderActionsLogService) {
    super();
  }

  actionsSubject = new BehaviorSubject<SelectionActionListItemFieldsFragment[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  totalCount = 0;
  loading = this.loadingSubject.asObservable();
  categoryMap = {};

  async loadRecords(
    orderId,
    toggleMarketingEvents: boolean,
    sortDirection?: string,
    pageIndex?: number,
    pageSize?: number,
    companyId?,
    dateLte?,
    dateGte?,
    listenToValueChanges = false,
    categories?: string[]
  ) {

    this.loadingSubject.next(true);

    const sortBy = this.sort?.active ? (this.sort.direction === 'asc' ? this.sort.active : `-${this.sort.active}`) : '-date';

    (
      listenToValueChanges
        ? this.dataService.getSelectionActionsListValuChanges(orderId, companyId, dateLte, dateGte, sortBy, categories)
        : this.dataService.getSelectionActionsList(orderId, companyId, dateLte, dateGte, sortBy, categories)
    )
    .subscribe(actionsResults => {
      console.log('actions log', actionsResults);
      if (actionsResults.data) {
        const actions = actionsResults.data.tasks.edges?.map(edge => {
          this.categoryMap[edge.node.id] = edge.node.category;
          return edge.node;
        });
        this.items.actions = actions;
        this.totalCount = [...this.items.actions, ...this.items.events].length;
        this.sortByDate({direction: this.sort.direction? this.sort.direction: 'desc'})
      }
    });
    if (toggleMarketingEvents) {
      this.dataService.getEventPlannerList(orderId)
      .subscribe(eventsResults => {
        if (eventsResults) {
          const events = eventsResults.data.plannerEvents.edges?.map(edge => edge.node)
          this.items.events = events;
          this.totalCount = [...this.items.actions, ...this.items.events].length;
          this.sortByDate({direction: this.sort.direction? this.sort.direction: 'desc'})
        }
      });
    }else  {
      this.items.events = [];
    }
  }

  sortByDate(sorrtValue) {
    let list = [...this.items.actions, ...this.items.events];
    if(sorrtValue.direction == 'asc') {
      list.sort((a: any, b: any) => {
        return <any>new Date(a.date) - <any>new Date(b.date);
      })
    } else {
      list.sort((a: any, b: any) => {
        return <any>new Date(b.date) - <any>new Date(a.date);
      })
    }
    this.actionsSubject.next(this.getPagedData(list))
  }

  // connect(collectionViewer: CollectionViewer): Observable<GpaisLocationTableFieldsFragment[]> {
  //   return this.actionsSubject.asObservable();
  // }
  /**
   * Connect this data source to the table. The table will only update when
   * the returned stream emits new items.
   * @returns A stream of the items to be rendered.
   */
  connect(): Observable<SelectionActionListItemFieldsFragment[]> {
    return this.actionsSubject.asObservable();
    // Combine everything that affects the rendered data into one update
    // stream for the data-table to consume.
    // const dataMutations = [
    //   observableOf(this.data),
    //   this.paginator.page,
    //   this.sort.sortChange
    // ];
    //
    // return merge(...dataMutations).pipe(map(() => {
    //   return this.getPagedData(this.getSortedData([...this.data]));
    // }));
  }

  /**
   *  Called when the table is being destroyed. Use this function, to clean up
   * any open connections or free any held resources that were set up during connect.
   */
  disconnect() {}

  /**
   * Paginate the data (client-side). If you're using server-side pagination,
   * this would be replaced by requesting the appropriate data from the server.
   */
  getPagedData(data: SelectionActionListItemFieldsFragment[]) {
    const startIndex = this.paginator.pageIndex * this.paginator.pageSize;

    return data.splice(startIndex, this.paginator.pageSize);
  }

  /**
   * Sort the data (client-side). If you're using server-side sorting,
   * this would be replaced by requesting the appropriate data from the server.
   */
  private getSortedData(data: SelectionActionListItemFieldsFragment[]) {
    if (!this.sort.active || this.sort.direction === '') {
      return data;
    }

    return data.sort((a, b) => {
      const isAsc = this.sort.direction === 'asc';
      switch (this.sort.active) {
        case 'name': return compare(a.comment, b.comment  , isAsc);
        case 'id': return compare(+a.id, +b.id, isAsc);
        default: return 0;
      }
    });
  }
}

/** Simple sort comparator for example ID/Name columns (for client-side sorting). */
function compare(a: string | number, b: string | number, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
