import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

import { GetUserProfilesGQL, UpdateCompanyManagersDataGQL, UserProfileMType } from '../../../generated/graphql';
import { Company } from '../../graphql/graphql';

@Component({
  selector: 'app-manager-assign',
  templateUrl: './manager-assign.component.html',
  styleUrls: ['./manager-assign.component.scss']
})
export class ManagerAssignComponent implements OnInit, OnDestroy {
  subscription = new Subscription();
  isLoading = true;

  managers: UserProfileMType[] = [];
  filteredManagers: UserProfileMType[] = [];

  selectedManagers: FormGroup;
  managerFilters: FormGroup;
  filterTarget: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { companies: Company[], preselect: Company },
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<ManagerAssignComponent>,
    private getManagers: GetUserProfilesGQL,
    private updateCompanyManagers: UpdateCompanyManagersDataGQL
  ) { }

  ngOnInit(): void {
    this.selectedManagers = this.fb.group({
      projectManager1: this.data.preselect?.projectManager1?.id || '',
      projectManager2: this.data.preselect?.projectManager2?.id || '',
      substituteManager1: this.data.preselect?.substituteManager1?.id || '',
      substituteManager2: this.data.preselect?.substituteManager2?.id || '',
      recruiter1: this.data.preselect?.recruiter1?.id || '',
      recruiter2: this.data.preselect?.recruiter2?.id || '',
      substituteRecruiter1: this.data.preselect?.substituteRecruiter1?.id || '',
      substituteRecruiter2: this.data.preselect?.substituteRecruiter2?.id || ''
    });

    this.selectedManagers.disable();

    this.managerFilters = this.fb.group(
      { pm1: '', pm2: '', sm1: '', sm2: '', r1: '', r2: '', sr1: '', sr2: '' }
    );

    this.subscription.add(
      this.managerFilters.valueChanges.subscribe(value => {
        Object.keys(value).forEach(key => {
          if (value[key]) {
            this.filterTarget = key;
            this.search(key);
          }
        });
      })
    );

    this.subscription.add(
      this.selectedManagers.valueChanges.subscribe(() => this.filterTarget = undefined)
    );

    this.getManagers.watch({}, { fetchPolicy: 'no-cache' }).valueChanges.subscribe(
      resp => {
        this.managers = resp.data.userprofiles as UserProfileMType[];
        this.selectedManagers.enable();
        this.isLoading = false;
      }
    );
  }

  search(key: string) {
    const filter = this.managerFilters.controls[key].value?.toLowerCase() || '';
    this.filteredManagers = this.managers.filter(option =>
      (option?.user?.firstName?.toLowerCase() + ' ' + option?.user?.lastName?.toLowerCase()).includes(filter)
    );
  }

  save() {
    const data = {};

    Object.keys(this.selectedManagers.value).forEach(key => {
      if (this.selectedManagers.value[key]) {
        data[key] = this.selectedManagers.value[key];
      }
    });

    this.isLoading = true;
    this.selectedManagers.disable();
    this.updateCompanyManagers.mutate({ companyIds: this.data.companies.map(company => company.id), ...data }).subscribe(
      () => this.dialogRef.close(true),
      () => {}
    );
  }

  isSaveDisable(): boolean {
    return Object.keys(this.selectedManagers.value).filter(key => this.selectedManagers.value[key]).length < 1;
  }

  getSelectedCompaniesRow(): string {
    return this.data.companies.map(company => company.name).join(', ');
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
