import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {PlannerRoutingModule} from './planner-routing.module';
import { PlannerTableComponent } from './planner-table/planner-table.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { TranslateModule } from '@ngx-translate/core';
import {SharedModule} from '../shared/shared.module';
import {AppMaterialModule} from '../app-material/app-material.module';
import {MatDialogModule} from '@angular/material/dialog';
import { PlannerComponent } from './planner/planner.component';
import { NewTaskDialogComponent } from './new-task-dialog/new-task-dialog.component';
import {MatTreeSelectInputModule} from 'mat-tree-select-input';
import { TaskSchedulingDialogComponent } from './task-scheduling-dialog/task-scheduling-dialog.component';
import { CustomfieldsModule } from '../customfields/customfields.module';
import { ScheduledTaskCreateNotificationComponent } from './planner-table/scheduled-task-create-notification/scheduled-task-create-notification.component';
import { TaskBucketsComponent } from './task-buckets/task-buckets.component';
import { TaskCardComponent } from './task-buckets/task-card/task-card.component';
import { TaskStatusPillComponent } from './task-status-pill/task-status-pill.component';

@NgModule({
  declarations: [
    PlannerTableComponent,
    PlannerComponent,
    NewTaskDialogComponent,
    TaskSchedulingDialogComponent,
    ScheduledTaskCreateNotificationComponent,
    TaskBucketsComponent,
    TaskCardComponent,
    TaskStatusPillComponent
  ],
  imports: [
    CommonModule,
    PlannerRoutingModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    TranslateModule,
    SharedModule,
    AppMaterialModule,
    MatDialogModule,
    MatTreeSelectInputModule,
    CustomfieldsModule
  ],
  exports: [
    TaskStatusPillComponent
  ]
})
export class PlannerModule { }
