<div fxLayout='row' fxLayoutAlign='space-between'>
  <h4>{{ 'SELECTION_ACTIONS_LOG' | translate }}</h4>
  <button mat-icon-button mat-dialog-close='' style='margin-left: auto'>
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-dialog-content>
  <div class="main-forms">
    <div>
      <mat-form-field appearance="outline" class="small-input">
        <mat-label>{{'ACTION_DESCRIPTION' | translate}}</mat-label>
        <input autocomplete="off" matInput [(ngModel)]='comment' name="description" placeholder="{{'ACTION_DESCRIPTION' | translate}}">
      </mat-form-field>
    </div>

    <div>
      <mat-form-field appearance='outline' class="small-input">
        <mat-label>{{ 'CATEGORY' | translate }}</mat-label>
        <input autocomplete="off" placeholder="{{ 'CATEGORY' | translate }}"
               type='text' matInput [(ngModel)]='selectedCategory'
               name="category"
                [matAutocomplete]='auto' (ngModelChange)='onCategoryChange($event)' (focus)="onCategoryChange('')">
        <mat-autocomplete #auto='matAutocomplete' [displayWith]='displayFn' [panelWidth]="'auto'">
          <mat-option *ngFor='let option of filteredOptions | async' [value]='option' [matTooltip]='option.title'>
            {{option.title}}
          </mat-option>
          <mat-divider></mat-divider>
          <mat-slide-toggle inline='true' color='primary'
                            class='options-slider'
                            [matTooltip]='"SHOW_ALL_CATEGORIES_INFO" | translate'
                            [(ngModel)]='showAllOptions'
                            (ngModelChange)='onCategoryChange(lastSearchedCategory)'
                            [disabled]='categiesOptionsSliderDisabled'>{{'SHOW_ALL_CATEGORIES' | translate}}</mat-slide-toggle>
        </mat-autocomplete>
      </mat-form-field>
    </div>

    <div>
      <!-- Add this code after the category input and before the owner select -->

      <mat-form-field appearance="outline" class="small-input">
        <mat-label>{{ 'DURATION_MINUTES' | translate }}</mat-label>
        <input matInput autocomplete="off" [(ngModel)]="durationMinutes" type="number" min="0">
      </mat-form-field>


    </div>

    <div class="owner-select">
      <app-popup-select-complete (changed)="onUserChange($event)"></app-popup-select-complete>
    </div>

    <div class="save-button">
      <button [disabled]='!comment?.length || !selectedCategory?.id' mat-raised-button
              color="primary"
              (click)='saveAction()'>
        <mat-icon>save</mat-icon>
        <span>{{'SAVE' | translate}}</span>
      </button>
    </div>
  </div>

  <mat-slide-toggle [checked]='toggleMarketingEvents' (change)='eventsPlannerToggle($event.checked)'>
    Marketing Actions
  </mat-slide-toggle>

  <div fxLayout='row'>
    <div fxFlex='66'>
      <app-log-items-table [orderId]='data?.orderId'
                           [country]='data.country'
                           [listenToValueChanges]='true'
                           [toggleMarketingEvents]='toggleMarketingEvents'></app-log-items-table>
    </div>
    <mat-divider [vertical]='true'></mat-divider>
    <div fxFlex='33' *ngIf='categoriesData'>
      <app-action-categories-tree [data]='categoriesData'></app-action-categories-tree>
    </div>
  </div>
  <!--  <app-log-list [orderId]="data?.orderId"></app-log-list>-->
</mat-dialog-content>
