import { Injectable } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ActionsLogDialogComponent} from './actions-log-dialog/actions-log-dialog.component';
import { Observable } from 'rxjs';
import {
  CreateSeletionActionGQL, DeleteSeletionActionGQL, GetPlannerEventsByOrderGQL, GetPlannerEventsGQL, GetSelectionActionGroupedCategoriesGQL,
  GetSelectionActionsGQL,
  SelectionActionListItemFieldsFragment, UpdateSeletionActionGQL
} from '../../../generated/graphql';
import {map} from 'rxjs/operators';
import { cloneDeep } from '@apollo/client/utilities';

@Injectable({
  providedIn: 'root'
})
export class OrderActionsLogService {

  constructor(
    private dialog: MatDialog,
    private getActionsForOrderGql: GetSelectionActionsGQL,
    private getEventsForOrderGql: GetPlannerEventsByOrderGQL,
    private getSelectionActionGroupedCategoriesGQL: GetSelectionActionGroupedCategoriesGQL,
    private createActionsForOrderGql: CreateSeletionActionGQL,
    private updateActionsForOrderGql: UpdateSeletionActionGQL,
    private deleteActionsForOrderGql: DeleteSeletionActionGQL
  ) { }

  openChangeLogDialog(orderId, country) {
    this.dialog.open(ActionsLogDialogComponent, {
      data: { orderId, country },
      minWidth: '80vw'

    });
  }

  getSelectionActionsListValuChanges(orderId, companyId, date_Lte, date_Gte, sortBy, categories) {
    return this.getActionsForOrderGql.watch({
      orderId,
      company: companyId,
      date_Lte,
      date_Gte,
      orderBy: sortBy,
      categories
    }).valueChanges;
  }

  getSelectionActionsList(orderId, companyId, date_Lte, date_Gte, sortBy, categories) {
    return this.getActionsForOrderGql.fetch({ orderId, company: companyId, date_Lte, date_Gte, orderBy: sortBy, categories })
      .pipe(map(cloneDeep));
  }

  getEventPlannerList(orderId) {
    return this.getEventsForOrderGql.watch({ orderId }, { fetchPolicy: 'cache-and-network' })
      .valueChanges.pipe(map(d => cloneDeep(d)));
  }

  getSACategoriesGroupsList(country?) {
    return this.getSelectionActionGroupedCategoriesGQL.fetch({ country });
  }


  createSelectionActionsListItem(orderId, comment: string, date: string, category: string, completedBy, durationMinutes?) {
    return this.createActionsForOrderGql.mutate(
      { orderId, comment, date, category, completedBy, durationMinutes }, { refetchQueries: ['GetSelectionActions'] }
    ).pipe(map(it => {
      if (it.data) {
        return it.data.createSelectionAction.task;
      } else {
        return [];
      }
      }));
  }

  updateSelectionActionListItem(row) {
    return this.updateActionsForOrderGql.mutate({
      id: row.id,
      comment: row.comment,
      category: row.category?.id,
      date: row.date,
      durationMinutes: row?.durationMinutes,
      completedBy: row?.completedBy.map(it => it.id)
    }, {refetchQueries: ['getPlannerEventsByCompany', 'GetSelectionActionGroupedCategories']})
      .pipe(map( it => {
        if (it.data) {
          return it.data.updateSelectionAction.task;
        } else {
          return  [];
        }
      }));
  }

  deleteSelectionActionListItem(actionId) {
    return this.deleteActionsForOrderGql.mutate(
      {id: actionId}, { refetchQueries: ['GetSelectionActions']});
  }
}
