import { DataSource } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import {debounce, debounceTime, map, switchMap, tap} from 'rxjs/operators';
import {Observable, of as observableOf, merge, BehaviorSubject, interval, Subscription} from 'rxjs';

import { GroupByPipe } from 'ngx-pipes';
import {FilterInterface} from '../../shared/filter-bar/filter-bar.component';
import {
  FreeVacanciesListFieldsFragment,
  GetFreeVacanciesOrderGQL
} from '../../../generated/graphql';
import { Moment } from 'moment';
import { FVMode } from './free-vacancies-table.component';

export interface FreeVacanciesTableItem extends FreeVacanciesListFieldsFragment {
  transportation?: string;
  orderCanBeExtendedComment?: string;
}


/**
 * Data source for the FreeVacanciesTable view. This class should
 * encapsulate all logic for fetching and manipulating the displayed data
 * (including sorting, pagination, and filtering).
 */
export class FreeVacanciesTableDataSource extends DataSource<FreeVacanciesTableItem> {
  data = new BehaviorSubject<FreeVacanciesTableItem[]>([]);
  paginator: MatPaginator;
  filters = new BehaviorSubject<FilterInterface>({});
  sort: MatSort;
  loading = new BehaviorSubject<boolean>(true);

  endCursor: string;
  totalCount: number;

  connection: Subscription;

  private gqlQuery;

  constructor(
    private groupBy: GroupByPipe,
    private tableGQL: GetFreeVacanciesOrderGQL,
    private mode: FVMode,
    private momentFrom: Moment,
    private momentTo: Moment
  ) {
    super();
  }

  /**
   * Connect this data source to the table. The table will only update when
   * the returned stream emits new items.
   * @returns A stream of the items to be rendered.
   */
  connect(): Observable<FreeVacanciesTableItem[]> {
    const dataMutations = [
      this.filters.asObservable(),
      this.paginator.page,
      this.sort.sortChange
    ];

    const self = this;

    this.connection = merge(...dataMutations)
      .pipe(tap(it => self.loading.next(true)))
      .pipe(debounceTime(800))
      .pipe(switchMap(() => {
        const endCursor = btoa(`arrayconnection:${(this.paginator.pageIndex) * this.paginator.pageSize - 1}`);
        const regions = this.filters.value.region;

        const filters = Object.assign({}, this.filters.value, {
          region: regions ? regions.join(',') : '',
          // orderBy: this.sort.active ? (this.sort.direction === 'desc' ? '-' : '' + this.sort.active) : ''
        });

        const eventSetDateRange = {
          date_Lte: this.mode === FVMode.marketing ? this.momentTo.format('YYYY-MM-DD') : null,
          date_Gte: this.mode === FVMode.marketing ? this.momentFrom.format('YYYY-MM-DD') : null,
        };

        const queryParams = Object.assign(
          {}, {first: this.paginator.pageSize, after: endCursor}, filters, eventSetDateRange
        );

        const gql = this.tableGQL.watch(
          queryParams,
          {fetchPolicy: 'cache-and-network'}
        );
        this.gqlQuery = gql;
        return gql.valueChanges.pipe(tap (() => console.log('f', filters)));

        })).subscribe(
          r => {

            if (r.data) {
              this.data.next(
                this.getSortedData(this.parseOrder(r.data.ordersPaginated.edges.map(it => it.node)))
              );
              this.totalCount = r.data.ordersPaginated.totalCount;
              this.endCursor = r.data.ordersPaginated.pageInfo.endCursor;
            }
            this.loading.next(r.loading);
          }
        );

    return this.data.asObservable();
  }

  /**
   *  Called when the table is being destroyed. Use this function, to clean up
   * any open connections or free any held resources that were set up during connect.
   */
  disconnect() {
    this.filters.complete();
    if (this.connection && this.connection?.unsubscribe) {this.connection?.unsubscribe?.()}
  }

  /**
   * Paginate the data (client-side). If you're using server-side pagination,
   * this would be replaced by requesting the appropriate data from the server.
   */
  private getPagedData(data: FreeVacanciesTableItem[]) {
    const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
    return data.splice(startIndex, this.paginator.pageSize);
  }

  /**
   * Sort the data (client-side). If you're using server-side sorting,
   * this would be replaced by requesting the appropriate data from the server.
   */
  private getSortedData(data: FreeVacanciesTableItem[]) {
    if (!this.sort.active || this.sort.direction === '') {
      return data;
    }

    return data.sort((a, b) => {
      const isAsc = this.sort.direction === 'asc';
      switch (this.sort.active) {
        case 'shortage': return compare(+a.shortage, +b.shortage, isAsc);
        default: return 0;
      }
    });
  }

  private parseOrder(data: FreeVacanciesTableItem[]) {
    const fv = data[0];


    // data.forEach(el => {
    //   if (el.orderperiodSet.length > 0) {
    //     el.demand = Math.max.apply(Math, el.orderperiodSet.map(o =>  o.demand ));
    //   } else {
    //     el.demand = 0;
    //   }
    //   if (el.specification) {
    //     el.specification.groupedAssignments = this.groupBy.transform(el.specification.tagassignmentSet, 'tag.group.key');
    //   }
    // });
    return data;
  }
}

/** Simple sort comparator for example ID/Name columns (for client-side sorting). */
function compare(a, b, isAsc) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
