<mat-progress-bar *ngIf="dataSource.loading | async" [mode]="'indeterminate'"></mat-progress-bar>

<div class="filter" *ngIf="useFilter">
  <mat-form-field style="width: 36%;">
    <mat-label>{{'DATE_RANGE' | translate}}</mat-label>
    <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
      <input matStartDate readonly formControlName="start" [placeholder]="'ORDER.START_DATE' | translate">
      <input matEndDate readonly formControlName="end" [placeholder]="'ORDER.END_DATE' | translate">
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>

    <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
    <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
  </mat-form-field>
</div>

<table mat-table class="ads-table " matSort aria-label="Ads" multiTemplateDataRows>
  <!-- Id Column -->
  <ng-container matColumnDef="date">
    <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'DATE' | translate }}</th>
    <td mat-cell *matCellDef="let row">
      {{dateFormattedBasedOnLocale(row?.date)}}
      <input [matDatepicker]="dt" [(ngModel)]="row.date" (ngModelChange)="onSave(row)" class="invisible-input">
      <mat-datepicker-toggle *ngIf="!readOnly" [for]="dt"></mat-datepicker-toggle>
      <mat-datepicker #dt></mat-datepicker>
    </td>
  </ng-container>

  <ng-container matColumnDef="title">
    <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'ORDER.POSITION_TITLE' | translate }}</th>
    <td mat-cell *matCellDef="let row">{{row?.order?.specification?.positionTitle}}</td>
  </ng-container>

  <ng-container matColumnDef="adId">
    <th mat-header-cell *matHeaderCellDef>{{'LIVAS_ID' | translate}}</th>
    <td mat-cell *matCellDef="let row">
      <div *ngIf="!readOnly; else readOnlyTemplate">
        <div (mouseenter)="showEditIcon[row.id + '_' + 'adId']"
        (mouseleave)="showEditIcon[row.id + '_' + 'adId'] = false">
          <div class="editable-cell" (click)="edit(row.id + '_' + 'adId', 'adId')" *ngIf="!editCell[row.id + '_' + 'adId'] else editAdId">
            {{row?.adId}}
            <span fxFlex fxLayoutAlign="end end" *ngIf="!editCell[row.id + '_' + 'adId'] && showEditIcon[row.id + '_' + 'adId']">
              <mat-icon>edit</mat-icon>
            </span>
          </div>
          <ng-template #editAdId>
            <mat-form-field [style.width.px]="100" appearance="outline" class="small-input">
              <mat-label>{{'LIVAS_ID' | translate}}</mat-label>
              <input name="adId" matInput type="number" [(ngModel)]="row.adId"
              (blur)="stopEditing(row, 'adId')"
              (ngModelChange)="onSave(row)"
              [ngModelOptions]="{updateOn: 'blur'}"
              fxFlex [rows]="5">
            </mat-form-field>
          </ng-template>
        </div>
      </div>
      <ng-template #readOnlyTemplate>
        <div>{{row?.adId}}</div>
      </ng-template>
    </td>
  </ng-container>

  <ng-container matColumnDef="completed">
    <th mat-header-cell *matHeaderCellDef>{{"COMPLETED" | translate}}</th>
    <td *matCellDef="let row" mat-cell>
      <app-popup-select-complete [selected]="row?.completedBy"
                                 (changed)="onSelectedProfilesChange($event, row)">

      </app-popup-select-complete>
    </td>
  </ng-container>

  <ng-container matColumnDef="externalPlatformId">
    <th mat-header-cell *matHeaderCellDef>{{'EXTERNAL_ID' | translate}}</th>
    <td mat-cell *matCellDef="let row">
      <div *ngIf="!readOnly; else readOnlyTemplate">
        <div (mouseenter)="showEditIcon[row.id + '_' + 'externalId']"
        (mouseleave)="showEditIcon[row.id + '_' + 'externalId'] = false">
          <div class="editable-cell" (click)="edit(row.id + '_' + 'externalId', 'externalId')" *ngIf="!editCell[row.id + '_' + 'externalId'] else editExternalId">
            {{row?.externalPlatformId}}
            <span fxFlex fxLayoutAlign="end end" *ngIf="!editCell[row.id + '_' + 'externalId'] && showEditIcon[row.id + '_' + 'externalId']">
              <mat-icon>edit</mat-icon>
            </span>
          </div>
          <ng-template #editExternalId>
            <mat-form-field [style.width.px]="100" appearance="outline" class="small-input">
              <mat-label>{{'EXTERNAL_ID' | translate}}</mat-label>
              <input name="externalId" matInput type="number" [(ngModel)]="row.externalPlatformId"
              (blur)="stopEditing(row, 'externalId')"
              (ngModelChange)="onSave(row)"
              [ngModelOptions]="{updateOn: 'blur'}"
              fxFlex [rows]="5">
            </mat-form-field>
          </ng-template>
        </div>
      </div>
      <ng-template #readOnlyTemplate>
        <div>{{row?.externalPlatformId}}</div>
      </ng-template>
    </td>
  </ng-container>

  <ng-container matColumnDef="duration">
    <th mat-header-cell *matHeaderCellDef>{{'DURATION' | translate}}</th>
    <td mat-cell *matCellDef="let row">
      <div *ngIf="!readOnly; else readOnlyTemplate">
        <div (mouseenter)="showEditIcon[row.id + '_' + 'duration'] = readOnly"
        (mouseleave)="showEditIcon[row.id + '_' + 'duration'] = false">
          <div class="editable-cell" (click)="edit(row.id + '_' + 'duration', 'duration')" *ngIf="!editCell[row.id + '_' + 'duration'] else editDuration">
            {{row?.durationMinutes | duration}}
            <span fxFlex fxLayoutAlign="end end" *ngIf="!editCell[row.id + '_' + 'duration'] && showEditIcon[row.id + '_' + 'duration']">
              <mat-icon>edit</mat-icon>
            </span>
          </div>
          <ng-template #editDuration>
            <mat-form-field [style.width.px]="100" appearance="outline" class="small-input">
              <mat-label>{{'DURATION' | translate}}</mat-label>
              <input name="duration" matInput type="number" [(ngModel)]="row.durationMinutes"
              (blur)="stopEditing(row, 'duration')"
              (ngModelChange)="onSave(row)"
              [ngModelOptions]="{updateOn: 'blur'}"
              fxFlex [rows]="5">
            </mat-form-field>
          </ng-template>
        </div>
      </div>
      <ng-template #readOnlyTemplate>
        <div>{{row?.durationMinutes | duration}}</div>
      </ng-template>
    </td>
  </ng-container>

  <ng-container matColumnDef="price">
    <th mat-header-cell *matHeaderCellDef>{{'PRICE' | translate}}</th>
    <td mat-cell *matCellDef="let row">
      <div *ngIf="!readOnly; else readOnlyTemplate">
        <div (mouseenter)="showEditIcon[row.id + '_' + 'price'] && true"
        (mouseleave)="showEditIcon[row.id + '_' + 'price'] = false">
          <div class="editable-cell" (click)="edit(row.id + '_' + 'price', 'price')" *ngIf="!editCell[row.id + '_' + 'price'] else editPrice">
            {{row?.price}}
            <span fxFlex fxLayoutAlign="end end" *ngIf="!editCell[row.id + '_' + 'price'] && showEditIcon[row.id + '_' + 'price']">
              <mat-icon>edit</mat-icon>
            </span>
          </div>
          <ng-template #editPrice>
            <mat-form-field [style.width.px]="100" appearance="outline" class="small-input">
              <mat-label>{{'PRICE' | translate}}</mat-label>
              <input name="price" matInput type="number" [(ngModel)]="row.price"
              (blur)="stopEditing(row, 'price')"
              (ngModelChange)="onSave(row)"
              [ngModelOptions]="{updateOn: 'blur'}"
              fxFlex [rows]="5">
              <mat-icon matSuffix>euro</mat-icon>
            </mat-form-field>
          </ng-template>
        </div>
      </div>
      <ng-template #readOnlyTemplate>
        <div>{{row?.price}}</div>
      </ng-template>
    </td>
  </ng-container>

  <ng-container matColumnDef="comment">
    <th mat-header-cell *matHeaderCellDef>{{'COMMENT' | translate}}</th>
    <td mat-cell *matCellDef="let row">
      <div *ngIf="!readOnly; else readOnlyTemplate">
        <div (mouseenter)="showEditIcon[row.id + '_' + 'comment']"
        (mouseleave)="showEditIcon[row.id + '_' + 'comment'] = false">
          <div class="editable-cell" (click)="edit(row.id + '_' + 'comment', 'comment')" *ngIf="!editCell[row.id + '_' + 'comment'] else editComment">
            {{row?.comment}}
            <span fxFlex fxLayoutAlign="end end" *ngIf="!editCell[row.id + '_' + 'comment'] && showEditIcon[row.id + '_' + 'comment']">
              <mat-icon>edit</mat-icon>
            </span>
          </div>
          <ng-template #editComment>
            <mat-form-field [style.width.px]="100" appearance="outline" class="small-input">
              <mat-label>{{'COMMENT' | translate}}</mat-label>
              <input name="comment" matInput [(ngModel)]="row.comment"
              (blur)="stopEditing(row, 'comment')"
              (ngModelChange)="onSave(row)"
              [ngModelOptions]="{updateOn: 'blur'}"
              fxFlex [rows]="5">
            </mat-form-field>
          </ng-template>
        </div>
      </div>
      <ng-template #readOnlyTemplate>
        <div>{{row?.comment}}</div>
      </ng-template>
    </td>
  </ng-container>

  <ng-container matColumnDef="category">
    <th mat-header-cell *matHeaderCellDef>{{'CATEGORY' | translate}}</th>
    <td mat-cell *matCellDef="let row"><mat-icon style="vertical-align: middle;">{{row?.category?.icon}}</mat-icon> {{row?.category?.title}}</td>
  </ng-container>

  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="status">{{'STATUS' | translate}}</th>
    <td mat-cell *matCellDef="let row" >
      <div fxLayout="column" fxLayoutAlign="center center">
        <span class="status {{row.status}}"
            [matTooltip]="('STATUS_EXPLANATION_' + row.status | uppercase) | translate">
          {{row.status.toUpperCase() | translate}}
        </span>
      </div>

    </td>
  </ng-container>

  <ng-container matColumnDef="channels">
    <th mat-header-cell *matHeaderCellDef>{{'CHANNELS' | translate}}</th>
    <td mat-cell *matCellDef="let row">
      <mat-chip-list #chipList>
        <mat-chip *ngFor="let channel of row?.channels">
          <mat-icon style="vertical-align: middle;">{{channel?.icon}}</mat-icon> {{channel?.title}}
        </mat-chip>
      </mat-chip-list>
    </td>
  </ng-container>

<!--  <ng-container matColumnDef="language">-->
<!--    <th mat-header-cell *matHeaderCellDef>{{'LANGUAGE.LABEL' | translate}}</th>-->
<!--    <td mat-cell *matCellDef="let row">-->
<!--      <mat-form-field appearance="fill" class="custom-form-field">-->
<!--        <mat-select [value]="row?.language" [(ngModel)]="row.language" (ngModelChange)="onSave(row)" class="custom-select">-->
<!--          <mat-option *ngFor="let lang of eventLanguages" [value]="lang.value" class="uppercase-option">-->
<!--            {{lang.label}}-->
<!--          </mat-option>-->
<!--        </mat-select>-->
<!--      </mat-form-field>-->
<!--    </td>-->
<!--  </ng-container>-->

  <ng-container matColumnDef="language">
    <th mat-header-cell *matHeaderCellDef>{{'LANGUAGE.LABEL' | translate}}</th>
    <td mat-cell *matCellDef="let row" [style.max-width.px]="60">
      <div class="select-container">
        <mat-form-field appearance="none" class="custom-form-field">
          <mat-select [value]="row?.language" [(ngModel)]="row.language" (ngModelChange)="onSave(row)" class="custom-select">
            <mat-option *ngFor="let lang of eventLanguages" [value]="lang.value" class="uppercase-option">
              {{lang.label}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="action">
    <th mat-header-cell *matHeaderCellDef>{{'ACTIONS' | translate}}</th>
    <td mat-cell *matCellDef="let row">
      <div>
        <div fxLayout="row">
          <button
          mat-icon-button
          *ngIf="row.status === statuses.planned"
          matTooltip="{{ 'DELETE' | translate }}"
        >
          <mat-icon (click)="onDelete(row)">delete</mat-icon>
        </button>

        <button
          mat-icon-button
          *ngIf="row.status === statuses.planned"
          matTooltip="{{ 'DONE' | translate }}"
        >
          <mat-icon (click)="onDone(row)">check</mat-icon>
        </button>

        <button
          mat-icon-button
          *ngIf="row?.workisJobId"
          matTooltip="{{ 'OPEN_IN_WORKIS' | translate }}"
        >
          <mat-icon (click)="onOpenNew(row)">open_in_new</mat-icon>
        </button>
        </div>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="row"></tr>

</table>

<mat-paginator #paginator
    [length]="dataSource?.totalCount"
    [pageIndex]="0"
    [pageSize]="20"
    [pageSizeOptions]="[10, 20, 50, 100]">
</mat-paginator>
