<div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between start">
  <h5> {{order.specification.positionTitle}} </h5>
  <button mat-icon-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-dialog-content fxLayoutGap="4px">
  <app-company-ads [orderId]="order.id" [useFilter]="false" [readOnly]="false"
                   (save)="onSave($event)" (done)="onDone($event)" (delete)="onDelete($event)"></app-company-ads>

  <app-fv-planing-dialog-action-item [action]="newEvent" [new]="true" (save)="onSave($event)">

  </app-fv-planing-dialog-action-item>
  <mat-hint style="font-size: 0.8em">
    <span>{{ 'TOTAL_CANDIDATES' | translate }}: {{totalCount}}</span>
  </mat-hint>
</mat-dialog-content>
