import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { MutationResult } from 'apollo-angular';

import {
  GetDashboardsGQL,
  GetMetricTableCardContentGQL,
  GetMetricTableCardNextTaskUpdateGQL,
  SetMetricValueNextTaskMutationGQL,
  SetMetricValueNextTaskL2MutationGQL,
  UpdateMetricValueCommentMutationGQL,
  UpdateMetricValueImportanceMutationGQL,
  SetMetricValueNextTaskMutationMutation,
  SetMetricValueNextTaskL2MutationMutation
} from '../../../generated/graphql';

export const SELECTED_DASHBOARD_KEY = 'selected_dashboard';

@Injectable({
  providedIn: 'root'
})
export class StandupDashboardServiceService {

  constructor(
    private getAllDashboards: GetDashboardsGQL,
    private getMetricTableValuesGQL: GetMetricTableCardContentGQL,
    private setMetricValueNextTaskMutationGQL: SetMetricValueNextTaskMutationGQL,
    private setMetricValueNextTaskL2MutationGQL: SetMetricValueNextTaskL2MutationGQL,
    private getMetricTableCardNextTaskUpdateGQL: GetMetricTableCardNextTaskUpdateGQL,
    private updateMetricValueCommentMutationGQL: UpdateMetricValueCommentMutationGQL,
    private updateMetricValueImportanceMutationsGQL: UpdateMetricValueImportanceMutationGQL
  ) { }

  getDashboards() {
    return this.getAllDashboards.watch().valueChanges.pipe(
      map(result => result.data.allDashboards.edges.map(edge => edge.node))
    );
  }

  getMetricTableValues(id: string, dashboardId: string, dateFrom?: string, dateTo?: string) {
    return this.getMetricTableValuesGQL.watch(
      { id, dashboardId, dateFrom, dateTo },
      { fetchPolicy: 'no-cache' }
    ).valueChanges.pipe(
      map(result => result.data.metricGroupsAssignment.edges.map(edge => edge.node))
    );
  }

  getUpdatedTask(id: string) {
    return this.getMetricTableCardNextTaskUpdateGQL.watch( { id }, { fetchPolicy: 'no-cache' }).valueChanges.pipe(
      map(result => result.data.tasks.edges[0].node)
    );
  }

  setMetricValueNextTask(id: string, taskId: string, refetchQueries?: string[])
    : Observable<MutationResult<SetMetricValueNextTaskMutationMutation>>
  {
    return this.setMetricValueNextTaskMutationGQL.mutate({ id, taskId }, { refetchQueries });
  }

  setMetricValueNextTaskL2(id: string, taskId: string, refetchQueries?: string[])
    : Observable<MutationResult<SetMetricValueNextTaskL2MutationMutation>>
  {
    return this.setMetricValueNextTaskL2MutationGQL.mutate({ id, taskId }, { refetchQueries });
  }

  updateMetricValueComment(id: string, comment: string) {
    return this.updateMetricValueCommentMutationGQL.mutate({ id, comment });
  }

  updateMetricValueImportance(id: string, isImportant: boolean) {
    return this.updateMetricValueImportanceMutationsGQL.mutate({ id, isImportant });
  }

  storeSelectedDashboard(dashboardId: string) {
    localStorage.setItem(SELECTED_DASHBOARD_KEY, dashboardId);
  }

  getStoredSelectedDashboard() {
    return localStorage.getItem(SELECTED_DASHBOARD_KEY);
  }
}
